
// webfontloader
WebFont.load({
    custom:{
        families: [
            "Noto Sans Japanese",
            "Sawarabi Mincho",
            "Roboto Condensed"
        ],
        urls: [
            "https://fonts.googleapis.com/earlyaccess/sawarabimincho.css",
            "https://fonts.googleapis.com/earlyaccess/notosansjapanese.css",
            "https://fonts.googleapis.com/css?family=Roboto+Condensed"
        ]
    },
    timeout: 3000
});


// smooth scroll
jQuery(document).ready(function($) {
    $('[data-smooth]').smoothScroll();
});


// slide-nav
jQuery(document).ready(function($){
	var MQL = 1170;

	//overlay-menu slide-in effect
	if($(window).width() > MQL) {
		var headerHeight = $('.side').height();
		$(window).on('scroll',
		{
	        previousTop: 0
	    },
	    function () {
		    var currentTop = $(window).scrollTop();
		    //check if user is scrolling up
		    if (currentTop < this.previousTop ) {
		    	//if scrolling up...
		    	if (currentTop > 0 && $('.side').hasClass('is-fixed')) {
		    		$('.side').addClass('is-visible');
		    	} else {
		    		$('.side').removeClass('is-visible is-fixed');
		    	}
		    } else {
		    	//if scrolling down...
		    	$('.side').removeClass('is-visible');
		    	if( currentTop > headerHeight && !$('.side').hasClass('is-fixed')) $('.side').addClass('is-fixed');
		    }
		    this.previousTop = currentTop;
		});
	}

	//open/close overlay-menu
	$('.nav-trigger').on('click', function(){
		$('.menu-icon').toggleClass('is-clicked');
		$('.side').toggleClass('menu-is-open');

		//in firefox transitions break when parent overflow is changed, so we need to wait for the end of the trasition to give the body an overflow hidden
		if( $('.overlay-menu').hasClass('is-visible') ) {
			$('.overlay-menu').removeClass('is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',function(){
				$('body').removeClass('overflow-hidden');
			});
		} else {
			$('.overlay-menu').addClass('is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',function(){
				$('body').addClass('overflow-hidden');
			});
		}
	});
});
